import "owl.carousel"
import moment from "moment/moment";

jQuery(document).ready(function ($) {

  // Scroll
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();

    const href = $(this).attr('href');
    const targetOffset = $(href).offset().top;
    const animationDuration = 500;

    $(".navbar").toggleClass("active");
    $('html, body').animate({
      scrollTop: targetOffset
    }, 
      animationDuration
    )
  });


  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $(".menu-navigation-menu-container").toggleClass('active')
    $("#nav-social").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.menu-item a').on('click', function () {
    if ($('.menu-navigation-menu-container').hasClass('active')) {
      $(".menu-navigation-menu-container").toggleClass('active')
      $("#nav-social").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })

  // VIDEO CAROUSEL
  function createVideoCarousel() {
    const videoCarousel = $("#video-carousel")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    videoCarousel.owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      stagePadding: 250,
      autoplayTimeout: 3000,
      autoplaySpeed: 500,
      center: true,
      responsive: {
        0: {
          stagePadding: 50,
          margin: 16,
        },
        700: {
          stagePadding: 250,
          margin: 43,
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createVideoCarousel()


  // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_15524438/events?app_id=45PRESS_darren-kiely',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#tour-dates-js');
      let html = '';
      let n = 0
      if (data.length) {
        for (let event of data) {
          n++
          html += '<div class="event-group">';
          html += '<div class="event-info">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn">' + offer.type.toUpperCase() + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>');
      }
      if (n < 9) {
        $("#toggle-dates").hide();
      }
    }
  });

});